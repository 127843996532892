import { firebaseStorage } from '@/firebase/firebase'
import { ref, getDownloadURL, uploadBytes, uploadString, deleteObject } from 'firebase/storage'
import { getUid } from '@/firebase/auth'
import { fetchUrl } from '@/helpers/fetch'
import { isImageFile } from '@/helpers/fileinfo'
import { checkValues } from '@/helpers/check-values'
import store from '../store'

const storageFileLocation = (fileType, fileName) => {
  if (!fileType) { return null }

  const userId = getUid()

  switch (fileType) {
    case 'receipts':
      return `users/${userId}/receipts/${fileName}`
    case 'userpic':
      return `users/${userId}/profile/userpic`
    default:
      return null
  }
}

export async function getStorageDownloadURL(fileType = null, fileName = null) {
  if (!fileType) { return false }

  try {
    const bucketName = storageFileLocation(fileType, fileName)
    const url = await getDownloadURL(ref(firebaseStorage, bucketName))
    return url
  } catch (error) {
    store.dispatch('saveErrorInfo', { error: error, location: 'getStorageDownloadURL', params: { fileType, fileName } })
    return false
  }
}

export async function uploadFileStringToStorage(fileType = null, fileName = null, fileString = null) {
  if (!fileType || !fileString) { return }

  try {
    const bucketName = storageFileLocation(fileType, fileName)

    const fileRef = ref(firebaseStorage, bucketName)

    const answer = await uploadString(fileRef, fileString, 'data_url')
    if (!answer) { return }

    const url = await getDownloadURL(fileRef)
    return url
  } catch (error) {
    store.dispatch('saveErrorInfo', { error: error, location: 'uploadFileStringToStorage', params: { fileType, fileName, fileString } })
    return false
  }
}

export async function uploadFileUrlToStorage(fileType = null, fileName = null, url = null) {
  try {
    if (!fileType || !url) { return }

    const urlResponse = await fetchUrl(url)
    if (!urlResponse) { return }

    const blob = await urlResponse.blob()
    if (!blob || (+blob.size > checkValues(fileType, 'bytes'))) { return }

    if (!isImageFile(blob)) { return }

    const bucketName = storageFileLocation(fileType, fileName)

    const fileRef = ref(firebaseStorage, bucketName)

    const answer = await uploadBytes(fileRef, blob)
    if (!answer) { return }

    return await getDownloadURL(fileRef)
  } catch (error) {
    store.dispatch('saveErrorInfo', { error: error, location: 'uploadFileUrlToStorage', params: { fileType, fileName, url } })
    return false
  }
}

export async function deleteImageFromStorage(fileType = null, fileName = null) {
  if (!fileType) { return false }

  try {
    const bucketName = storageFileLocation(fileType, fileName)

    await deleteObject(ref(firebaseStorage, bucketName))
    return true
  } catch (error) {
    if (error.code === 'storage/object-not-found') { return true }

    store.dispatch('saveErrorInfo', { error: error, location: 'deleteImageFromStorage', params: { fileType, fileName } })
    return false
  }
}