import store from '../store'
import localizeFilter from '@/filters/localize.filter'

export default function currencyFilter(value, currencyCode) {
  if (value === false) { return localizeFilter('CantCount') }

  let sum = 0

  if (value) { sum = +value / 100 }

  if (!currencyCode) { currencyCode = 'USD' }

  const locale = store.getters.locale || 'en-US'
  return new Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode }).format(+sum)
}
