import localizeFilter from '@/filters/localize.filter'

export default function totalSum(obj, defaultCurrency, rates) {
  if (!obj || !defaultCurrency || !rates) { return localizeFilter('CantCount') }

  let sum = 0

  const currencies = Object.keys(obj)

  for (const i in currencies) {
    if (currencies[i] === defaultCurrency) {
      sum = sum + obj[currencies[i]]
    } else {
      const rate = rates[currencies[i]]
      if (!rate) { return false }
      sum = sum + Math.round(obj[currencies[i]] / rate)
    }
  }

  return sum
}
