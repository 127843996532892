// =1=
// v-tooltip.right="{ // left, right, bottom, top
//   text: 'abc',
//   padding: 6, // px
//   shown: true,
//   startAfter: 500, // ms
//   location: 'top' // left, right, bottom, top
// }"

// =2=
// v-tooltip="'abc'"

export default {
  name: 'tooltip',
  async createTooltip(el, dir, tooltipId, { location = 'right', padding = 0, startAfter = 0 }) {
    const text = el.getAttribute('data-tooltip-text')
    if (!text) { return }

    const tooltipElement = document.createElement('tooltip')
    tooltipElement.classList.add('tooltip')
    tooltipElement.classList.add('tooltip-hidden')
    tooltipElement.id = tooltipId

    const tooltipBody = document.createElement('div')
    tooltipBody.innerHTML = text
    tooltipBody.classList.add('tooltip-body')

    const tooltipArrow = document.createElement('div')
    tooltipArrow.classList.add('tooltip-arrow')

    const setByLocation = () => {
      tooltipBody.style.textAlign = 'left'
      if (location === 'left') { tooltipBody.style.textAlign = 'right' }

      tooltipArrow.classList.remove('tooltip-arrow-right')
      tooltipArrow.classList.remove('tooltip-arrow-left')
      tooltipArrow.classList.remove('tooltip-arrow-top')
      tooltipArrow.classList.remove('tooltip-arrow-bottom')

      if (location === 'left') {
        tooltipArrow.classList.add('tooltip-arrow-right')
      } else if (location === 'right') {
        tooltipArrow.classList.add('tooltip-arrow-left')
      } else if (location === 'bottom') {
        tooltipArrow.classList.add('tooltip-arrow-top')
      } else if (location === 'top') {
        tooltipArrow.classList.add('tooltip-arrow-bottom')
      }
    }

    setByLocation()

    tooltipElement.appendChild(tooltipBody)
    tooltipElement.appendChild(tooltipArrow)

    document.body.appendChild(tooltipElement)

    const pause = (miliseconds = 1000) => {
      return new Promise((resolve) => {
        setTimeout(resolve, miliseconds)
      })
    }

    await pause(startAfter)

    const elementRect = el.getBoundingClientRect()
    const elementTopBottomMiddle = (elementRect.left + (elementRect.width / 2))

    let tooltipLeft = null
    let tooltipRight = null
    let tooltipTop = null
    let tooltipBottom = null

    let tooltipArrowLeft = null

    const setParams = () => {
      if (location === 'left' || location === 'right') {
        tooltipTop = (elementRect.top + (elementRect.width / 2) - (tooltipElement.clientHeight / 2) + window.scrollY - 2.5)
      }

      if (location === 'top' || location === 'bottom') {
        tooltipLeft = (elementTopBottomMiddle - (tooltipElement.clientWidth / 2))
        tooltipArrowLeft = (tooltipElement.clientWidth / 2) + 7.5
      }

      tooltipBottom = ''
      tooltipRight = ''

      if (location === 'left') {
        tooltipLeft = (elementRect.left - tooltipElement.clientWidth - 2.5 - 1 + window.scrollX - +padding)
      } else if (location === 'right') {
        tooltipLeft = (elementRect.left + elementRect.width + window.scrollX + 2.5 + 1 + +padding)
      } else if (location === 'bottom') {
        tooltipTop = (elementRect.top + elementRect.height + window.scrollY + 2.5 + 1 + +padding)
      } else if (location === 'top') {
        tooltipTop = (elementRect.top - tooltipElement.clientHeight + window.scrollY - 2.5 - 1 - +padding)
      }

      const bodyRect = document.body.getBoundingClientRect()
      if ((tooltipElement.clientWidth + 10) > bodyRect.width) {
        tooltipElement.style.maxWidth = (bodyRect.width - 10) + 'px'
      }

      if (tooltipLeft && tooltipLeft < 0) {
        tooltipLeft = 5
        tooltipArrowLeft = elementRect.left + (elementRect.width / 2)
      }

      if (tooltipRight && tooltipRight > bodyRect.width) {
        tooltipRight = bodyRect.width - 5
        tooltipArrowLeft = (tooltipElement.clientWidth / 2) + (elementRect.width / 2) - 5
      }

      if (tooltipLeft && (tooltipLeft + tooltipElement.clientWidth) > bodyRect.width) {
        tooltipLeft = bodyRect.width - tooltipElement.clientWidth - 5
        tooltipArrowLeft = elementTopBottomMiddle - (tooltipLeft) - 5
      }

      if (tooltipArrowLeft !== null && (tooltipArrowLeft < 10)) {
        tooltipArrowLeft = 10
      }

      if (tooltipArrowLeft !== null && (tooltipArrowLeft > (tooltipElement.clientWidth - 10))) {
        tooltipArrowLeft = tooltipElement.clientWidth - 10
      }
    }

    setParams()

    if ((tooltipTop - window.scrollY) < 0) {
      location = 'bottom'
      setByLocation()
      setParams()
    }

    if (tooltipLeft) { tooltipElement.style.left = tooltipLeft + 'px' }
    if (tooltipRight) { tooltipElement.style.right = tooltipRight + 'px' }
    if (tooltipTop) { tooltipElement.style.top = tooltipTop + 'px' }
    if (tooltipBottom) { tooltipElement.style.bottom = tooltipBottom + 'px' }

    if (tooltipArrowLeft) { tooltipArrow.style.left = tooltipArrowLeft + 'px' }

    await pause(100)

    tooltipElement.classList.remove('tooltip-hidden')
  },
  hideTooltip(el, dir) {
    const keepAlive = el.getAttribute('data-tooltip-keep-alive')
    if (keepAlive === true || keepAlive === 'true') { return }
    const tooltipId = el.getAttribute('data-tooltip-id')
    if (!tooltipId) { return }
    const tooltip = document.getElementById(tooltipId)
    if (!tooltip) { return }
    tooltip.classList.add('tooltip-hidden')
    setTimeout(() => {
      dir.removeTooltip(el)
    }, 300)
  },
  removeTooltip(el) {
    const tooltipId = el.getAttribute('data-tooltip-id')
    if (!tooltipId) { return }
    const tooltip = document.getElementById(tooltipId)
    if (tooltip && tooltip.remove) { tooltip.remove() }
  },
  async updateTooltip(el, { dir, value, modifiers }) {
    const tooltipId = el.getAttribute('data-tooltip-id')
    if (!tooltipId) { return }
    dir.removeTooltip(el)

    let location = 'top'
    if (value.location && ['top', 'bottom', 'left', 'right'].includes(value.location)) {
      location = value.location
    } else {
      if (modifiers.left) {
        location = 'left'
      } else if (modifiers.right) {
        location = 'right'
      } else if (modifiers.bottom) {
        location = 'bottom'
      }
    }

    let padding = 0
    if (value && value.padding) { padding = value.padding }

    let startAfter = 0
    if (value && value.startAfter) { startAfter = value.startAfter }

    dir.createTooltip(el, dir, tooltipId, { location, padding, startAfter })
  },
  async checkShown(el, { dir, value, modifiers }) {
    el.removeAttribute('data-tooltip-keep-alive')

    let shown = false
    if (value && value.shown) {
      if (typeof value.shown === 'function') {
        shown = await shown()
      } else if (typeof value.shown === 'boolean') {
        shown = value.shown
      }
    }

    if (shown) {
      el.setAttribute('data-tooltip-keep-alive', true)
      dir.updateTooltip(el, { dir, value, modifiers })
    }
  },
  async setTooltipText(el, value) {
    let text = ''
    if (typeof value === 'object' && value.text) {
      if (typeof value.text === 'function') {
        text = await value.text()
      } else {
        text = value.text
      }
    } else if (typeof value === 'string') {
      text = value
    } else if (typeof value === 'function') {
      text = await value()
    }
    el.setAttribute('data-tooltip-text', text)
  },
  async mounted(el, { dir, value, modifiers }) {
    const tooltopId = (Date.now() + Math.random()) + ''
    el.setAttribute('data-tooltip-id', tooltopId)

    await dir.setTooltipText(el, value)

    await dir.checkShown(el, { dir, value, modifiers })

    el.__hoverHandler__ = event => {
      if ((el === event.target || el.contains(event.target))) {
        dir.updateTooltip(el, { dir, value, modifiers })
      }
    }

    el.__hideTooltip__ = () => {
      dir.hideTooltip(el, dir)
    }

    el.__removeTooltip__ = () => {
      dir.removeTooltip(el)
      el.blur()
    }

    el.addEventListener('mouseenter', el.__hoverHandler__)
    el.addEventListener('mouseleave', el.__hideTooltip__)
    el.addEventListener('click', el.__removeTooltip__)
  },
  async updated(el, { dir, value, modifiers }) {
    dir.setTooltipText(el, value)
    dir.removeTooltip(el)
    dir.checkShown(el, { dir, value, modifiers })
  },
  unmounted(el) {
    if (el.__hideTooltip__) { el.__hideTooltip__() }
    el.removeEventListener('mouseenter', el.__hoverHandler__)
    el.removeEventListener('mouseleave', el.__hideTooltip__)
    el.removeEventListener('click', el.__removeTooltip__)
  }
}