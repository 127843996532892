import { trace } from 'firebase/performance'
import { performance } from './firebase'
import store from '../store'

export function tracePerformance(traceName = null) {
  if (!traceName) { return }

  try {
    return trace(performance, traceName)
  } catch (error) {
    store.dispatch('saveErrorInfo', { error: error, location: 'tracePerformance', params: { traceName } })
  }
}