import { logEvent } from 'firebase/analytics'
import { analytics } from './firebase'
import store from '../store'

export async function logAnalyticsEvent(eventName = null, eventParams = null) {
  if (!eventName) { return }
  if (!process.env.NODE_ENV !== 'production') { return }

  try {
    if (eventParams && Object.keys(eventParams).length) {
      logEvent(await analytics(), eventName, eventParams)
    } else {
      logEvent(await analytics(), eventName)
    }
  } catch (error) {
    store.dispatch('saveErrorInfo', { error: error, location: 'logAnalyticsEvent', params: { eventName: eventName, eventParams: eventParams } })
  }
}