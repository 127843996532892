import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAnalytics, isSupported as isSupportedAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'
import { getRemoteConfig, isSupported as isSupportedRemoteConfig } from 'firebase/remote-config'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASURMENTID
}

export const firebaseApp = initializeApp(firebaseConfig)
export const auth = getAuth(firebaseApp)
export const firestoreDB = getFirestore(firebaseApp)
export const firebaseStorage = getStorage(firebaseApp)
export const analytics = async () => await isSupportedAnalytics() && getAnalytics(firebaseApp)
export const performance = getPerformance(firebaseApp)
export const remoteConfig = async () => await isSupportedRemoteConfig() && getRemoteConfig(firebaseApp)