import store from '../store'

const locales = {
  'ru-RU': require('../locales/ru-RU.json'),
  'en-US': require('../locales/en-US.json')
}

function saveLocaleError(locale, key) {
  store.dispatch('saveErrorInfo', {
    error: 'NO-KEY-FOUND',
    params: {
      locale: locale,
      key: key
    },
    location: 'localizeFilter'
  })
}

export default function localizeFilter(key) {
  const locale = store.getters.locale || 'en-US'

  let localeKey = locales[locale][key]

  if (!localeKey) {
    saveLocaleError(locale, key)
    localeKey = locales['en-US'][key]

    if (!localeKey) {
      saveLocaleError('en-US', key)
      localeKey = 'NO-KEY-FOUND'
    }
  }

  return localeKey
}