export default {
  name: 'long-hover',
  mounted(el, { value }) {
    el.hoverDuration = 3000
    el.hovered = false
    el.timeoutId = null
    el.func = null
    el.funcParam = null

    if (!value.func) { return }
    el.func = value.func
    if (value.hoverDuration) { el.hoverDuration = +value.hoverDuration }
    if (value.funcParam) { el.funcParam = value.funcParam }

    el.__handleMouseEnter__ = (event) => {
      if ((el !== event.target || !el.contains(event.target))) { return }

      event.stopPropagation()

      el.hovered = true

      if (el.hovered) {
        el.timeoutId = setTimeout(() => {
          if (el.hovered) {
            if (el.func) { el.func(el.funcParam) }
            el.timeoutId = null
          }
        }, el.hoverDuration)
      }
    }

    el.__handleMouseLeave__ = (event) => {
      event.stopPropagation()
      el.hovered = false
      if (el.timeoutId) { clearTimeout(el.timeoutId) }
      el.timeoutId = null
    }

    el.__handleClick__ = (event) => {
      if ((el === event.target || el.contains(event.target))) {
        event.stopPropagation()
        el.hovered = false
        if (el.timeoutId) { clearTimeout(el.timeoutId) }
        el.timeoutId = null
      }
    }

    el.addEventListener('mouseenter', el.__handleMouseEnter__)
    el.addEventListener('mouseleave', el.__handleMouseLeave__)
    el.addEventListener('click', el.__handleClick__)
  },
  unmounted(el) {
    el.removeEventListener('mouseenter', el.__handleMouseEnter__)
    el.removeEventListener('mouseleave', el.__handleMouseLeave__)
    el.removeEventListener('click', el.__handleClick__)
  }
}