import VClickOutside from '@/directives/VClickOutside'
import VFocus from '@/directives/VFocus'
import VIntersection from '@/directives/VIntersection'
import VLongHover from '@/directives/VLongHover'
import VTooltip from '@/directives/VTooltip'

export default [
  VClickOutside,
  VFocus,
  VIntersection,
  VLongHover,
  VTooltip
]